import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const GoogleAdsense: React.FC = () => {
  const { site } = useStaticQuery<GatsbyTypes.GoogleAdsenseQuery>(
    graphql`
      query GoogleAdsense {
        site {
          siteMetadata {
            google_adsense_id
          }
        }
      }
    `
  )

  const googleAdsenseId = site?.siteMetadata?.google_adsense_id || `undefined`

  const adsenseUrl = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${googleAdsenseId}`

  return (
    <>
      <script
        id="google-adsense-script"
        src={adsenseUrl}
        async
        crossOrigin="anonymous"
      />
    </>
  )
}

export default GoogleAdsense