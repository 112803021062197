import React from "react"
import { Link, graphql, PageProps } from "gatsby"

import Bio from "../components/Bio"
import Layout from "../components/layout"
import SEO from "../components/SEO"

export { Head } from "../functions/Head"

const About: React.FC<PageProps<GatsbyTypes.IndexQuery>> = ({ data, location }) => {
  const siteTitle = data.site?.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About" />
      <p>
        ふじい たかふみ（ピルティ）<br/>
        関東でプログラマやってます。<br/>
        <br/>
        ここでの発言は個人的な見解であり、所属する企業や団体とは関係ありません。<br/>
      </p>
      <Bio />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query About {
    site {
      siteMetadata {
        title
      }
    }
  }
`
