import React from "react"
import GoogleAdsense from "../components/GoogleAdsense"

export const Head = () => {

  return (
    <>
      <GoogleAdsense />
    </>
  )
}
